import './App.css';
import {Box, Container, Typography} from "@mui/material";
import ProTip from "./components/ProTip";
import Profile from "./components/auth/Profile";
import {Fragment} from "react";
import Header from "./components/nav/Header";

const Copyright = () => {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {`Copyright © FusionKoding ${new Date().getFullYear()}.`}
        </Typography>
    );
}


const App = () => {
    return (
        <Fragment>
            <Header/>
            <Container maxWidth="sm">
                <Box sx={{my: 4}}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome to FusionHost
                    </Typography>
                    <Typography variant="h6" component="h2" gutterBottom>
                        A Gameserver Manager
                    </Typography>
                    <Profile/>
                    <ProTip/>
                    <Copyright/>
                </Box>
            </Container>
        </Fragment>
    );
}

export default App;
