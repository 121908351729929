import React, {useState} from 'react';
import {
    AppBar,
    Avatar,
    Box,
    Button,
    Container,
    Divider,
    IconButton,
    Menu,
    MenuItem,
    Toolbar,
    Tooltip,
    Typography
} from "@mui/material";
import {useAuth0} from "@auth0/auth0-react";
import LogoutButton from "../auth/LogoutButton";
import LoginButton from "../auth/LoginButton";

function Header() {

    const {user, isAuthenticated} = useAuth0();
    const [anchorElUser, setAnchorElUser] = useState(null);


    const pages = [
        "Dashboard"
    ]

    const settings = []

    const handleCloseNavMenu = () => {

    }

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
                    >
                        FusionHost ({process.env.NODE_ENV})
                    </Typography>

                        <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                            {pages.map((page) => (
                                <Button
                                    key={page}
                                    onClick={handleCloseNavMenu}
                                    sx={{my: 2, color: 'white', display: 'block'}}
                                >
                                    {page}
                                </Button>
                            ))}
                        </Box>

                    {isAuthenticated &&
                        <Box sx={{flexGrow: 0}}>
                            <Tooltip title="Open settings">
                                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                                    <Avatar alt={user.email} src={user.picture}/>
                                </IconButton>
                            </Tooltip>
                            <Menu
                                sx={{mt: '45px'}}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                                <Divider/>
                                <MenuItem onClick={handleCloseUserMenu}>
                                    <LogoutButton/>
                                </MenuItem>
                            </Menu>
                        </Box>
                    }
                    {!isAuthenticated &&
                        <Box sx={{flexGrow: 0}}>
                            <LoginButton/>
                        </Box>}
                </Toolbar>
            </Container>
        </AppBar>
    );
}

export default Header;
