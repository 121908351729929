import { useAuth0 } from "@auth0/auth0-react";
import {useEffect, useState} from "react";

const Profile = () => {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const [userMetadata, setUserMetadata] = useState(null);

    useEffect(() => {
        const getUserMetadata = async () => {

            try {
                const accessToken = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH_AUDIENCE,
                    scope: process.env.REACT_APP_AUTH_SCOPE,
                });

                const userDetailsByIdUrl = `${process.env.REACT_APP_FUSIONHOST_API}v1/squads/`;

                const metadataResponse = await fetch(userDetailsByIdUrl, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });

                console.log(metadataResponse)

                const squad = await metadataResponse.json();
                console.log(squad)

                setUserMetadata(squad);
            } catch (e) {
                console.log(e.message);
            }
        };

        getUserMetadata();
    }, [getAccessTokenSilently, user?.sub]);

    return (
        isAuthenticated && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
                <h3>User Metadata</h3>
                {userMetadata ? (
                    <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
                ) : (
                    "No user metadata defined"
                )}
            </div>
        )
    );
};

export default Profile;
